import { SET_FILTER, RESET_FILTERS } from "./types";

const initialState = {
  city: "",
  usageType: "",
  propertyType: "",
  auctionType: "",
  vehicleModel: "",
  vehicleMake: "",
  fromYear: "",
  toYear: "",
};

const FilterStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case RESET_FILTERS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default FilterStore;
