import {
  ACTIVE_PRODUCT,
  AUCTIONS_EXAMPLE,
  AUCTION_BIDS,
  AUCTION_DETAILS,
  BID_INCREMENT,
  HIGHEST_BID,
  START_PRICE,
  INSURANCE_CURRENT_AUCTION,
  INSURANCE_MODAL,
  STATUS_TYPE,
  TRANSFER_INSURANCE_MODAL,
  CONNECTION_FAILED,
} from "./types";

const initialState = {
  auctionsExample: ["hi", "hello"],
  auctionDetails: {},
  auctionBids: null,
  activeProduct: null,
  statusType: null,
  isInsuranceModalOpen: false,
  isTransferInsuranceModalOpen: false,
  insuranceCurrentAuction: null,
  highestBid: null,
  bidIncrement: null,
  startPrice: null,
  connectionFailed: false,
};

const AuctionStore = (state = initialState, action) => {
  switch (action.type) {
    case AUCTIONS_EXAMPLE:
      return {
        ...state,
        auctionsExamples: action.payload,
      };
    case AUCTION_DETAILS:
      return {
        ...state,
        auctionDetails: action.payload,
      };
    case AUCTION_BIDS:
      return {
        ...state,
        auctionBids: action.payload,
      };
    case ACTIVE_PRODUCT:
      return {
        ...state,
        activeProduct: action.payload,
      };
    case STATUS_TYPE:
      return {
        ...state,
        statusType: action.payload,
      };
    case INSURANCE_MODAL:
      return {
        ...state,
        isInsuranceModalOpen: Boolean(action.payload)
          ? action.payload
          : !state.isInsuranceModalOpen,
        ...(state.isTransferInsuranceModalOpen && {
          insuranceCurrentAuction: null,
        }),
      };
    case TRANSFER_INSURANCE_MODAL:
      return {
        ...state,
        isInsuranceModalOpen: false,
        isTransferInsuranceModalOpen: Boolean(action.payload)
          ? action.payload
          : !state.isTransferInsuranceModalOpen,
        ...(state.isTransferInsuranceModalOpen && {
          insuranceCurrentAuction: null,
        }),
      };
    case INSURANCE_CURRENT_AUCTION:
      return {
        ...state,
        insuranceCurrentAuction: action.payload,
      };
    case HIGHEST_BID:
      return {
        ...state,
        highestBid: action.payload,
      };
    case BID_INCREMENT:
      return {
        ...state,
        bidIncrement: action.payload,
      };
    case START_PRICE:
      return {
        ...state,
        startPrice: action.payload,
      };
    case CONNECTION_FAILED:
      return {
        ...state,
        connectionFailed: action.payload,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};
export default AuctionStore;
