import { io } from "socket.io-client";

const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL;

class WebSocketService {
  constructor(url) {
    if (!WebSocketService.instance) {
      this.url = url;
      this.socket = null;
      this.isConnected = false;
      this.reconnectAttempts = 0;
      this.maxReconnectAttempts = 5;
      this.onStatusChange = null;
      this.pendingMessages = [];
      this.pingInterval = null;
      WebSocketService.instance = this;
      this.connect();
    }
    return WebSocketService.instance;
  }

  connect() {
    if (!this.socket || !this.socket.connected) {
      this.socket = io(this.url, {
        reconnectionAttempts: this.maxReconnectAttempts,
        reconnectionDelay: 1000,
        autoConnect: true,
      });

      this.socket.on("connect", () => {
        this.isConnected = true;
        this.reconnectAttempts = 0;
        if (this.onStatusChange) this.onStatusChange(this.isConnected);

        this.pendingMessages.forEach((msg) => this.sendMessage(msg));
        this.pendingMessages = [];

        this.keepAlive();
      });

      this.socket.on("disconnect", (reason) => {
        console.log("Socket.IO disconnected:", reason);
        this.isConnected = false;
        if (this.onStatusChange) this.onStatusChange(this.isConnected);
      });

      this.socket.on("connect_error", (error) => {
        console.error("Socket.IO connection error:", error);
      });
    }
  }

  reconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts += 1;
      this.socket.connect();
    } else {
      console.error("Max reconnect attempts reached. Failed to reconnect.");
    }
  }

  onMessage(callback) {
    if (this.socket) {
      this.socket.onAny((event, ...args) => {
        const raw = args[0];
        if (typeof raw === "string") {
          try {
            const parsed = JSON.parse(raw);
            callback({ action: event, ...parsed });
          } catch (e) {
            console.error("Invalid JSON:", raw);
          }
        } else {
          callback({ action: event, ...raw });
        }
      });
    }
  }

  sendMessage(message) {
    if (this.socket && this.isConnected) {
      this.socket.emit(message.action, message);
    } else {
      this.pendingMessages.push(message);
    }
  }

  keepAlive() {
    this.stopPing();

    this.pingInterval = setInterval(() => {
      this.sendMessage({ action: "ping" });
    }, 30000);
  }

  stopPing() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }

  close() {
    if (this.socket) {
      this.socket.disconnect();
      this.isConnected = false;
      if (this.onStatusChange) this.onStatusChange(this.isConnected);
    }
  }

  setStatusChangeCallback(callback) {
    this.onStatusChange = callback;
  }
}

const webSocketService = new WebSocketService(WSS_BASE_URL);

export default webSocketService;
